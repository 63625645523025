import { IDropDownItem } from '../../baseComponents/DropDown';
import {
  Chart_Bin_Type,
  DigestDataFragment,
  Digest_Group_Status,
  EntryFragment,
  InsightCollection,
  InsightLightFragment,
  Insight_Status,
} from '../../generated/graphql';
import { getInitialMutateInsightState, getInitialMutateInsightStateForEditing } from '../../v3/components/digests/insights/CreateInsightFromCollectionModal';
import { NOT_FOUND } from '../utilities/consts';
import { assertDropDownItemValidEnumSelection } from '../utilities/enumHandling';

export interface InsightState {
  digestOptions: IDropDownItem[];
  chosenDigest: IDropDownItem;
  previewEntries: { entry: EntryFragment; isSelected: boolean }[];
  canSelectMoreEntries: boolean;
  /**
   * This is supposed to store the Insight Status -> going well / needs improvement
   *
   * the name feedbackStatus is what is our feedback about the insight
   */
  feedbackStatus: IDropDownItem;
  teamId: number;
  orgId: number;
  collection: InsightCollection;
  createSaveLoading: boolean;

  title: string;
  sources: string;
  text: string;
  expiration: Date;

  order: number;
  excludeFromDigest: boolean;

  insightStatus: IDropDownItem;
  chartBin: IDropDownItem;

  editingMode: boolean; //We set this to true from the Creation modal to know we're editing an existing insight for the selected digest.
  id: number; //If the insight exists inside the Creation modal, we'll use this id to update it.

  checkingExistingInsightLoading: boolean;
}

export enum InsightAction {
  SetSources,
  SetDigestOptions,
  SetSelectedDigest,
  SetPreviewEntries,
  SetEntrySelected,
  SetFeedbackStatus,
  SetLoading,
  SetInsightTitle,
  SetInsightText,
  SetInsightExpiration,
  SaveInsight,

  SetInitialState,
  SetChartBin,
  SetInsightStatus,
  SetCheckLoading,
  SetExcludeFromDigest,
  SetEntrySentiment,
}

interface SelectionPayload {
  selection: IDropDownItem;
}

interface SelectEntryPayload {
  selected: boolean;
  entryId: string;
}

interface TextActionPayload {
  text: string;
}
interface DateActionPayload {
  date: Date;
}

interface FeedbackStatusPayload {
  status: IDropDownItem;
}
interface SetLoadingPayload {
  loading: boolean;
}

interface SetCheckLoadingPayload {
  loading: boolean;
}

interface SetExcludeFromDigestPayload {
  exclude: boolean;
}

interface SetSelectedDigestPayload {
  selection: IDropDownItem;
  insight?: InsightLightFragment;
}

interface SetSourcesPayload {
  sources: string;
}

interface SetEntrySentiment {
  entryId: string;
  sentiment: number;
}

interface SaveInsightPayload {}

export type InsightPayload =
  | DigestDataFragment[]
  | SelectionPayload
  | EntryFragment[]
  | SelectEntryPayload
  | TextActionPayload
  | FeedbackStatusPayload
  | SetLoadingPayload
  | InsightState
  | DateActionPayload
  | SetSelectedDigestPayload
  | SaveInsightPayload
  | SetEntrySentiment;

export const InsightReducer = (state: InsightState, action: { type: InsightAction; payload: InsightPayload }): InsightState => {
  switch (action.type) {
    case InsightAction.SetDigestOptions:
      return setDigestOptions(state, action.payload as DigestDataFragment[]);
    case InsightAction.SetSelectedDigest:
      return setSelectedDigest(state, action.payload as SelectionPayload);
    case InsightAction.SetPreviewEntries:
      return setPreviewEntries(state, action.payload as EntryFragment[]);
    case InsightAction.SetEntrySelected:
      return selectEntry(state, action.payload as SelectEntryPayload);
    case InsightAction.SetInsightText:
      return setInsightText(state, action.payload as TextActionPayload);
    case InsightAction.SetInsightTitle:
      return setInsightTitle(state, action.payload as TextActionPayload);
    case InsightAction.SetInsightExpiration:
      return setInsightExpiration(state, action.payload as DateActionPayload);
    case InsightAction.SetFeedbackStatus:
      return setFeedbackStatus(state, action.payload as FeedbackStatusPayload);
    case InsightAction.SaveInsight:
      return saveInsight(state, action.payload as SaveInsightPayload);
    case InsightAction.SetLoading:
      return setLoading(state, action.payload as SetLoadingPayload);
    case InsightAction.SetChartBin:
      return selectChartBin(state, action.payload as SelectionPayload);
    case InsightAction.SetInsightStatus:
      return selectInsightStatus(state, action.payload as SelectionPayload);
    case InsightAction.SetInitialState:
      return setInitialState(action.payload as InsightState);
    case InsightAction.SetCheckLoading:
      return setCheckLoading(state, action.payload as SetCheckLoadingPayload);
    case InsightAction.SetExcludeFromDigest:
      return setExcludeFromDigest(state, action.payload as SetExcludeFromDigestPayload);
    case InsightAction.SetSources:
      return setSources(state, action.payload as SetSourcesPayload);
    case InsightAction.SetEntrySentiment:
      return setEntrySentiment(state, action.payload as SetEntrySentiment);
  }
  throw new Error(`Cannot perform requested action type ${action.type}`);
};

const setInitialState = (newState: InsightState): InsightState => {
  return newState;
};

const selectChartBin = (state: InsightState, payload: SelectionPayload): InsightState => {
  assertDropDownItemValidEnumSelection(Chart_Bin_Type, payload.selection);
  return {
    ...state,
    chartBin: payload.selection,
  };
};

const selectInsightStatus = (state: InsightState, payload: SelectionPayload): InsightState => {
  assertDropDownItemValidEnumSelection(Insight_Status, payload.selection);
  return {
    ...state,
    insightStatus: payload.selection,
  };
};
const setLoading = (state: InsightState, payload: SetLoadingPayload): InsightState => {
  return { ...state, createSaveLoading: payload.loading };
};

const setCheckLoading = (state: InsightState, payload: SetCheckLoadingPayload): InsightState => {
  return { ...state, checkingExistingInsightLoading: payload.loading };
};

const saveInsight = (state: InsightState, payload: SaveInsightPayload): InsightState => {
  return { ...state };
};

const setFeedbackStatus = (state: InsightState, payload: FeedbackStatusPayload): InsightState => {
  assertDropDownItemValidEnumSelection(Digest_Group_Status, payload.status);
  return { ...state, feedbackStatus: payload.status };
};
const setInsightText = (state: InsightState, payload: TextActionPayload): InsightState => {
  return { ...state, text: payload.text };
};
const setInsightTitle = (state: InsightState, payload: TextActionPayload): InsightState => {
  return { ...state, title: payload.text };
};
const setInsightExpiration = (state: InsightState, payload: DateActionPayload): InsightState => {
  return { ...state, expiration: payload.date };
};
const selectEntry = (state: InsightState, entry: SelectEntryPayload): InsightState => {
  const newPreviewEntries = [...state.previewEntries];
  const indexOfEntry = newPreviewEntries.findIndex((en) => en.entry.id === entry.entryId);
  if (indexOfEntry === NOT_FOUND) {
    throw new Error(`Could not find entry id ${entry.entryId}`);
  }
  newPreviewEntries[indexOfEntry].isSelected = entry.selected;

  return {
    ...state,
    previewEntries: newPreviewEntries,
    canSelectMoreEntries: valueLessThanMaxAllowedEntries(newPreviewEntries.filter((val) => val.isSelected).length),
  };
};

const setPreviewEntries = (state: InsightState, previewEntries: EntryFragment[]): InsightState => {
  let newPreviewEntries: {
    entry: EntryFragment;
    isSelected: boolean;
  }[] = [];

  if (state.previewEntries.length === 0) {
    newPreviewEntries = previewEntries.map((entry, index) => {
      return { entry, isSelected: valueLessThanMaxAllowedEntries(index), canSelectMoreEntries: false };
    });
  } else {
    // our insight has entries on it.
    // However the entries need to match what comes back in the previewEntries.
    // If the entries on the insight don't match what comes back in the preview it means the entry
    // is outside of the filter set.
    newPreviewEntries = previewEntries.map((entry) => {
      const existingEntry = state.previewEntries.find((en) => en.entry.id === entry.id);
      if (existingEntry) {
        return existingEntry;
      }
      return { entry, isSelected: false };
    });
  }

  return {
    ...state,
    previewEntries: newPreviewEntries,
    canSelectMoreEntries: valueLessThanMaxAllowedEntries(newPreviewEntries.filter((val) => val.isSelected).length),
  };
};

const valueLessThanMaxAllowedEntries = (num: number) => {
  return num < 3;
};
/**
 * If an insight already exists for the digest and the collection id and type this is responsible for setting fields appropriately
 * @param state
 * @param selectedDigest
 * @param insight (optional)
 * @returns
 */
const setSelectedDigest = (state: InsightState, payload: SetSelectedDigestPayload): InsightState => {
  const { selection: selectedDigest, insight } = payload;
  let newState;

  if (selectedDigest.id === NOT_FOUND) newState = getInitialMutateInsightState({ teamId: state.teamId, orgId: state.orgId, collection: state.collection });
  if (insight) newState = getInitialMutateInsightStateForEditing({ insight, digestBasic: selectedDigest });
  else newState = getInitialMutateInsightState({ teamId: state.teamId, orgId: state.orgId, collection: state.collection });

  return { ...newState, digestOptions: state.digestOptions, chosenDigest: selectedDigest /*previewEntries:*/ };
};

const setDigestOptions = (state: InsightState, options: DigestDataFragment[]): InsightState => {
  const sortedOptions = [...options].sort((a, b) => {
    return a.dateCreated > b.dateCreated ? -1 : 1;
  });
  const digestOptions = sortedOptions.map((digest) => {
    return {
      id: digest.id,
      name: `${digest.digestSeries.title} - Created ${new Date(digest.dateCreated).toDateString()} - ${digest.status}`,
    };
  });
  return {
    ...state,
    digestOptions,
  };
};

const setExcludeFromDigest = (state: InsightState, payload: SetExcludeFromDigestPayload): InsightState => {
  return { ...state, excludeFromDigest: payload.exclude };
};

const setSources = (state: InsightState, payload: SetSourcesPayload): InsightState => {

  return { ...state, sources: payload.sources};
};

const setEntrySentiment = (state: InsightState, payload: SetEntrySentiment): InsightState => {
  const filteredEntries = state.previewEntries.map((entry) => {
    return entry.entry.id === payload.entryId ? { ...entry, entry: { ...entry.entry, sentiment: payload.sentiment } } : entry;
  }) ;
  return { ...state, previewEntries: filteredEntries };
};