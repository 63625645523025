import { useNavigate } from 'react-router-dom';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { AppRoutes } from '../../../Routes';

import InsightSliderView from './insightSliderView';
import { FilterInput, GetActiveInsightsForTeamQuery, useGetActiveInsightsForTeamQuery } from '../../../generated/graphql';
import { getGroupPageUrl } from '../../lib/groups';

const InsightSliderController = (props: InsightSliderControllerProps) => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const navigate = useNavigate();
  // fetch insights for list - get insights for team
  const { data, loading, error } = useGetActiveInsightsForTeamQuery({ variables: { teamId } });

  const openGroup = (groupId: string, filterInput: FilterInput) => {
    navigate(getGroupPageUrl(teamId, orgId, groupId, AppRoutes.v3FullPath.home, [`group=${JSON.stringify(filterInput)}`]));
  };
  return <InsightSliderControllerView data={data} loading={loading} error={error} openGroup={openGroup} pageName={props.pageName} />;
};

export const InsightSliderControllerView = (props: InsightSliderControllerViewProps) => {
  const { loading, error, data } = props;
  /**
   * This is kinda janky. We don't want to show anything in the insights section explicitly if we're loading or have an error.
   * At least not yet.
   */
  if (loading || error) {
    return <div data-testid="loading-error" className="absolute w-0 h-0"></div>;
  }

  /**
   * This is explicitly checking if we don't have active insights to try and load the 'old' insights
   */
  if (data && data.getActiveInsightsForTeam.length > 0) {
    return (
      <InsightSliderView
        insightsNoSources={[...data.getActiveInsightsForTeam].sort((a, b) => a.order - b.order)}
        openGroup={props.openGroup}
        pageName={props.pageName}
      />
    );
  } else {
    return <></>;
  }
};

interface InsightSliderControllerViewProps {
  pageName: string;
  loading: boolean;
  error: Error | undefined;
  data: GetActiveInsightsForTeamQuery | undefined;
  openGroup: (groupId: string, filterInput: FilterInput) => void;
}

interface InsightSliderControllerProps {
  pageName: string;
}

export default InsightSliderController;
