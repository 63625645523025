import { OrganizationFragment, OrganizationUserFragment, TeamDataFragment } from '../../generated/graphql';

export interface OrgInfoState {
  organization: OrganizationFragment;
}

export enum OrgInfoActionTypes {
  SetOrg,
  SetTitle,
  AddMember,
  RemoveMember,
  EditMember,
  EditMembers,
  AddView,
  RemoveView,
  EditView,
  SetDefaultTeam,
}

type SetOrgPayload = { organization: OrganizationFragment };
type SetTitlePayload = { title: string };
type AddMemberPayload = { member: OrganizationUserFragment };
type RemoveMemberPayload = { userEmail: string };
type EditMemberPayload = { userId: number; updatedMember: Partial<OrganizationUserFragment> };
type EditMembersPayload = { updates: { userId: number; updatedMember: Partial<OrganizationUserFragment> }[] };
type AddViewPayload = { view: TeamDataFragment };
type RemoveViewPayload = { viewId: number };
type EditViewPayload = { viewId: number; updatedView: TeamDataFragment };
type SetDefaultTeamPayload = { defaultTeamId: number | null };

export type OrgInfoAction =
  | { type: OrgInfoActionTypes.SetOrg; payload: SetOrgPayload }
  | { type: OrgInfoActionTypes.SetTitle; payload: SetTitlePayload }
  | { type: OrgInfoActionTypes.AddMember; payload: AddMemberPayload }
  | { type: OrgInfoActionTypes.RemoveMember; payload: RemoveMemberPayload }
  | { type: OrgInfoActionTypes.EditMember; payload: EditMemberPayload }
  | { type: OrgInfoActionTypes.AddView; payload: AddViewPayload }
  | { type: OrgInfoActionTypes.RemoveView; payload: RemoveViewPayload }
  | { type: OrgInfoActionTypes.EditView; payload: EditViewPayload }
  | { type: OrgInfoActionTypes.EditMembers; payload: EditMembersPayload }
  | { type: OrgInfoActionTypes.SetDefaultTeam; payload: SetDefaultTeamPayload };

export const OrgInfoReducer = (state: OrgInfoState, action: OrgInfoAction): OrgInfoState => {
  switch (action.type) {
    case OrgInfoActionTypes.SetOrg:
      return { ...state, organization: action.payload.organization };

    case OrgInfoActionTypes.SetTitle:
      return {
        ...state,
        organization: {
          ...state.organization,
          name: action.payload.title,
        },
      };

    case OrgInfoActionTypes.AddMember:
      return {
        ...state,
        organization: {
          ...state.organization,
          orgUser: updateOrAddMember(state.organization.orgUser, action.payload.member),
        },
      };

    case OrgInfoActionTypes.RemoveMember:
      return {
        ...state,
        organization: {
          ...state.organization,
          orgUser: removeMember(state.organization.orgUser, action.payload.userEmail),
        },
      };

    case OrgInfoActionTypes.EditMember:
      return {
        ...state,
        organization: {
          ...state.organization,
          orgUser: editMember(state.organization.orgUser, action.payload.userId, action.payload.updatedMember),
        },
      };

    case OrgInfoActionTypes.EditMembers:
      return {
        ...state,
        organization: {
          ...state.organization,
          orgUser: editMultipleMembers(state.organization.orgUser, action.payload.updates),
        },
      };

    case OrgInfoActionTypes.AddView:
      return {
        ...state,
        organization: {
          ...state.organization,
          teams: [...state.organization.teams, action.payload.view],
        },
      };

    case OrgInfoActionTypes.RemoveView:
      return {
        ...state,
        organization: {
          ...state.organization,
          teams: removeView(state.organization.teams, action.payload.viewId),
        },
      };

    case OrgInfoActionTypes.EditView:
      return {
        ...state,
        organization: {
          ...state.organization,
          teams: editView(state.organization.teams, action.payload.viewId, action.payload.updatedView),
        },
      };

    case OrgInfoActionTypes.SetDefaultTeam:
      return {
        ...state,
        organization: {
          ...state.organization,
          defaultTeamId: action.payload.defaultTeamId
        }
      };

    default:
      throw new Error('Unhandled action type');
  }
};

const updateOrAddMember = (orgUsers: OrganizationUserFragment[], newMember: OrganizationUserFragment): OrganizationUserFragment[] => {
  const index = orgUsers.findIndex(member => member.email === newMember.email);
  if (index !== -1) {
    return [
      ...orgUsers.slice(0, index),
      newMember,
      ...orgUsers.slice(index + 1)
    ];
  }
  return [...orgUsers, newMember];
};

const removeMember = (orgUsers: OrganizationUserFragment[], userEmail: string): OrganizationUserFragment[] => 
  orgUsers.filter(member => member.email !== userEmail);

const editMember = (orgUsers: OrganizationUserFragment[], userId: number, updatedMember: Partial<OrganizationUserFragment>): OrganizationUserFragment[] =>
  orgUsers.map(member => member.user?.id === userId ? { ...member, ...updatedMember } : member);

const editMultipleMembers = (orgUsers: OrganizationUserFragment[], updates: { userId: number; updatedMember: Partial<OrganizationUserFragment> }[]): OrganizationUserFragment[] =>
  orgUsers.map(member => {
    const update = updates.find(update => update.userId === member.user?.id);
    return update ? { ...member, ...update.updatedMember } : member;
  });

const removeView = (teams: TeamDataFragment[], viewId: number): TeamDataFragment[] =>
  teams.filter(view => view.id !== viewId);

const editView = (teams: TeamDataFragment[], viewId: number, updatedView: TeamDataFragment): TeamDataFragment[] =>
  teams.map(view => view.id === viewId ? { ...view, ...updatedView } : view);